<template>
  <div>
    <div class="top">
      <img src="../../../../static/image/left.png" @click="back" alt="" />
      <div class="order_text">认证状态</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  background-color: #ffffff;
  margin-bottom: 21px;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 35%;
  }
}
</style>